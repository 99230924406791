import Vue from 'vue'
import UUID from 'vue-uuid';
import VueCookies from 'vue-cookies';
import device from "vue-device-detector";
import VueLazyload from 'vue-lazyload';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js';
import 'vue-plyr/dist/vue-plyr.css';
  
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue';
import router from './router';
import titleMixin from './mixins/titleMixin';
import { i18n } from './plugins/i18n.js';
import store from './store';

Vue.config.productionTip = false;

Vue.mixin(titleMixin);
Vue.use(UUID);
Vue.use(VueCookies);
Vue.use(device);
Vue.use(VueLazyload);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VuePlyr, {
    plyr: {}
})

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')