<template>
  <div>{{ $t("redirecting.text") }}</div>
</template>

<script>
import axios from "axios";
import { helpers } from "../helper/portal_config";

export default {
  name: "DoLogin",
  data() {
    return {
      accessCode: "",
      trackings: {
        dlpc: "",
        daff: "",
        dtrv: "",
        dsrc: "",
        dusr: "",
        dtim: "",
        devt: "",
      },
      currentLanguage: "",
      currentCountry: ""
    };
  },
  methods: {
    doLogin() {
      axios
        .get(
          `https://api.hubdelivery.io/store/checkAccessCode?portal_name=${
            helpers.portal_name
          }&country=${this.currentCountry}&language=${this.currentLanguage}&access_code=${
            this.accessCode
          }`
        )
        .then((response) => {
          if (response.data.treatment == 1) {
            this.$store
              .dispatch("globals/login", {
                accessCode: this.accessCode,
              })
              .then(() => {
                this.$router.push("/");
              });
          } else {
            this.$router.go(-1); // wrong code go back
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    startTracking(evt) {
      this.trStartTim();
      this.trSetEvt(evt);
      this.trSendToServer();
    },
    trSendToServer() {
      this.trackings.dtim = parseInt(this.trStopTim());
      const $time = new Date().getTime();
      axios
        .post(
          `${helpers.trackingAPI}?call=${$time}`,
          {},
          {
            headers: {
              accept: "text/html",
              "d-lpc": this.trackings.dlpc,
              "d-aff": this.trackings.daff,
              "d-trv": this.trackings.dtrv,
              "d-src": this.trackings.dsrc,
              "d-usr": this.$cookies.get("d_user_id"),
              "d-tim": this.trackings.dtim,
              "d-evt": this.trackings.devt,
            },
          }
        )
        .then((response) => {})
        .catch((response) => {
          console.error(response);
        });
    },
    generateUUID() {
      return this.$uuid.v1();
    },
    trStartTim() {
      return window.performance.now();
    },
    trStopTim() {
      return window.performance.now();
    },
    trSetEvt(evt) {
      this.trackings.devt = evt;
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.country_default_language.hasOwnProperty(country)) {
        lang = helpers.country_default_language[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
    trSetParamsItems($q) {
      // set default value
      this.trackings.daff = helpers.affiliate_landing;
      this.trackings.dsrc = helpers.source_landing;
      this.trackings.dtrv = this.generateUUID();
      this.trackings.dlpc = helpers.landing_page_id;
      if (!this.$cookies.get("d_user_id")) {
        this.$cookies.set("d_user_id", this.generateUUID());
      }
      if (Object.keys($q).length == 0) return;

      const paramsLength = Object.entries($q).length;
      for (const [key, value] of Object.entries($q)) {
        console.log(key);
        console.log(value);
        if (key === "trv") {
          this.trackings.dtrv = value;
        }
        if (key === "src") {
          this.trackings.dsrc = value;
        }
        if (key === "aff") {
          this.trackings.daff = value;
        }
        if (key === "lpc") {
          this.trackings.dlpc = value;
        }
        if (key === "usr") {
          if (!this.$cookies.get("d_user_id")) {
            this.$cookies.set("d_user_id", value);
          }
        }
      }
    },
  },
  created() {
    this.trSetParamsItems(this.$route.query);
    this.setCurrentLanguage();
    this.setCurrentCountry();
  },
  mounted() {
    // trackings
    document.addEventListener("DOMContentLoaded", (event) => {
      this.startTracking("hload");
    });

    window.addEventListener("load", (event) => {
      this.startTracking("fload");
      if (
        this.$route.params.accesscode &&
        this.$route.params.accesscode.length > 0
      ) {
        this.accessCode = this.$route.params.accesscode;
        setTimeout(() => {
          this.doLogin();
        }, 1500);
      } else {
        this.$router.go(-1);
      }
    });
  },
};
</script>
