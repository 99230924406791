import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "../plugins/i18n.js";
import { loadLanguageAsync } from "@/plugins/i18n.js";

Vue.config.silent = true;

//pin flow
const NotFound = () => import("@/views/NotFound.vue");

// Music
const MusicHome = () => import("@/views/music/home.vue");
const MusicCategories = () => import("@/views/music/categories.vue");
const MusicCategoryPage = () => import("@/views/music/category_page.vue");
const MusicChannel = () => import("@/views/music/channel.vue");

const Profile = () => import("@/views/profile/index.vue");
const Landing = () => import("@/views/landing/index.vue");
const Login = () => import("@/views/auth/login.vue");

const Register = () => import("@/views/auth/register.vue");
const Register2 = () => import("@/views/auth/register2.vue");
const Register3 = () => import("@/views/auth/register3.vue");

// Concerts
const ConcertsHome = () => import("@/views/concerts/home.vue");
const ConcertsCategories = () => import("@/views/concerts/categories.vue");
const ConcertsCategoryPage = () => import("@/views/concerts/category_page.vue");
const ConcertsConcert = () => import("@/views/concerts/concert.vue");

// Legals
const LegalsAboutUs = () => import("@/views/legals/about_us.vue");
const LegalsContact = () => import("@/views/legals/contact.vue");
const LegalsFaq = () => import("@/views/legals/faq.vue");
const LegalsTerms = () => import("@/views/legals/terms.vue");
const LegalsPrivacy = () => import("@/views/legals/privacy.vue");
const LegalsCookiesPolicy = () => import("@/views/legals/cookies_policy.vue");
const LegalsImpressum = () => import("@/views/legals/impressum.vue");
const LegalsUnsubscribe = () => import("@/views/legals/unsubscribe.vue");

import DoLogin from '../components/DoLogin.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dologin/:accesscode',
    component: DoLogin,
  },
  {
    path: "/error",
    component: NotFound,
  },

  // Music
  {
    path: "/",
    component: MusicHome, 
  },
  {
    path: "/music/categories",
    component: MusicCategories,
    meta: {
        requiresAccessCode: true,
    },
  },
  {
    path: "/music/category/:category_id",
    component: MusicCategoryPage,
    meta: {
        requiresAccessCode: true,
    },
  },
  {
    path: "/music/channel/:channel_id",
    component: MusicChannel,
    meta: {
        requiresAccessCode: true,
    },
  },

  // Concerts URIs
  {
    path: "/concerts",
    component: ConcertsHome, 
  },
  {
    path: "/concerts/categories",
    component: ConcertsCategories,
    meta: {
        requiresAccessCode: true,
    },
  },
  {
    path: "/concerts/category/:category_id",
    component: ConcertsCategoryPage,
    meta: {
        requiresAccessCode: true,
    },
  },
  {
    path: "/concerts/concert/:concert_id",
    component: ConcertsConcert,
    meta: {
        requiresAccessCode: true,
    },
  },

  // General URIs
  {
    path: "/profile",
    component: Profile,
    meta: {
        requiresAccessCode: true
    },
  },

  {
    path: "/landing",
    component: Landing,
  },

  {
    path: "/login",
    component: Login,
  },

  {
    path: "/register",
    component: Register,
  },

  {
    path: "/register2",
    component: Register2,
  },

  {
    path: "/register3",
    component: Register3,
  },

  // Legals
  {
    path: "/about-us",
    component: LegalsAboutUs,
  },
  {
    path: "/contact",
    component: LegalsContact,
  },
  {
    path: "/faq",
    component: LegalsFaq,
  },
  {
    path: "/terms",
    component: LegalsTerms,
  },
  {
    path: "/privacy",
    component: LegalsPrivacy,
  },
  {
    path: "/cookies-policy",
    component: LegalsCookiesPolicy,
  },
  {
    path: "/impressum",
    component: LegalsImpressum,
  },
  {
    path: "/unsubscribe",
    component: LegalsUnsubscribe,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 400);
    });
  },
});

router.beforeEach((to, from, next) => {
  const { host } = window.location;
  const parts = host.split(".");
  const lang = parts[0];

  if (to.matched.some((record) => record.meta.requiresAccessCode)) {
    
    if (localStorage.getItem("accessCode") == null) {
      next({
        path: "/",
      });
    }
  }

  let trad_lang;
  if (lang.substring(0, 9) == "localhost" || lang.substring(0, 2) == "uk") {
    trad_lang = "en";
  } else if (lang && lang.length >= 2) {
    trad_lang = lang.substring(0, 2);
  }

  i18n.fallbackLocale = "en";
  loadLanguageAsync(trad_lang).then(() => next());
});

export default router;
