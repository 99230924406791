<template>
   <div class="page-wrapper">
    <transition name="fade" mode="out-in">
        <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
};

import { helpers } from "@/helper/portal_config";
import("./assets/css/"+helpers.portal_name+".css");

</script>

<style scoped>
.fade-enter,
.fade-leave-to,
.fade-leave-active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>
