import axios from "axios";

const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGOUT = 'LOGOUT';
const CODE = 'SET_ACCESS_CODE';
const CLEAR_CODE = 'CLEAR_ACCESS_CODE';

const SEARCH_RESULT = 'SEARCH_RESULT';
const SEARCH_WORD = 'SEARCH_WORD';

export default {
    namespaced: true,
    state: {
        accessCode: localStorage.getItem('accessCode'),
        isLoggedIn: !!localStorage.getItem('token'),
        results: [],
        query: '',
    },
    getters: {
        isLoggedIn: (state) => state.isLoggedIn,
        accessCode: (state) => state.accessCode,
        searchResults: (state) => state.results,
        query: (state) => state.query
    },
    mutations: {
        [LOGIN](state) {
            state.pending = true;
        },
        [LOGIN_SUCCESS](state) {
            state.isLoggedIn = true;
            state.pending = false;
        },
        [LOGOUT](state) {
            state.isLoggedIn = false;
        },
        [CODE](state, payload) {
            state.accessCode = payload;
        },
        [CLEAR_CODE](state) {
            state.accessCode = '';
        },
        [SEARCH_RESULT](state, results) {
            state.results = results;
        },
        [SEARCH_WORD](state, word) {
            state.query = word;
        }
    },
    actions: {
        login({ commit, state }, payload) {
            commit(LOGIN); // show spinner
            return new Promise((resolve) => {
                setTimeout(() => {
                    localStorage.setItem('token', 'JWT');
                    localStorage.setItem('accessCode', payload.accessCode);
                    commit(LOGIN_SUCCESS);
                    commit(CODE, payload.accessCode);
                    resolve();
                }, 1000);
            });
        },

        logout({ commit }) {
            localStorage.removeItem('token');
            localStorage.removeItem('accessCode');
            commit(LOGOUT);
            commit(CLEAR_CODE);
        },

        async getSearchResults({ commit }, params) {
            const res = await axios.get(
                `${params.api}?portal_name=${params.portalName}&country=${params.country}&word=${params.q}`
            );
            commit(SEARCH_RESULT, res.data.products);
            commit(SEARCH_WORD, params.q);
        },
    },
};
