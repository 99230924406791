/* Portal configurations - global scope */

const helper = {};

// base config
helper.portal_name = 'SoundeesMusic';
helper.portal_url = "soundees-music.com";

// api methods
helper.api_url = 'https://api.hubdelivery.io';
helper.api_methods = {
    'music': {
        'categories' : '/soundees/music/categories',
        'channels_by_category' : '/soundees/music/channels_by_category',
        'channels_by_list' : '/soundees/music/channels_by_list',
        'channel' : '/soundees/music/channel',
        'playlist' : '/soundees/music/playlist',
        'track' : '/soundees/music/track'
    },
    'legals': {
        'contact' : '/soundees/legals/contact',
        'faq' : '/soundees/legals/faq',
        'terms' : '/soundees/legals/terms',
        'privacy' : '/soundees/legals/privacy',
        'cookies_policy' : '/soundees/legals/cookies_policy',
        'unsubscribe_text' : '/soundees/legals/unsubscribe',
        'unsubscribe_phone' : '/store/UnsubscribePhoneNumber'
    },
    'general': {
        'login' : '/store/checkAccessCode'
    }
};

helper.country_phone_format = {
    'UK': '44xxxxxxxxx',
    'LT': '6xxxxxxx',
    'DE': '42xxxxxxxxx',
    'BG': '08xxxxxxxx',
    'SK': '09xxxxxxxx',
    'PL': '48xxxxxxxxx',
    'RS': '06xxxxxxxx'
};

helper.country_default_language = {
    'UK': 'EN',
    'IE': 'EN',
    'LU': 'EN',
    'CH': 'DE'
};

helper.country_register_landing = {
    'UK': 'https://uk.soundees-music.com/register',
    'BG': 'https://premium.soundees-music.com/bgSm01/',
    'DE': 'https://premium.soundees-music.com/deSm01/clk/',
    'RS': 'https://premium.soundees-music.com/rSSm01/',
    'LT': 'https://premium.soundees-music.com/ltSm01/'
};


export const helpers = helper;